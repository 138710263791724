<template>
  <article class="lookbook" @click="$emit('click', item)">
    <div class="lookbook_img">
      <image-block-src :src="item.cit_file_1" />
    </div>

    <div class="con pro_desc_box type2 posiab">
      <image-block-src :src="item.provider.img_url" class="profile_img" />
      <div class="profile_user">
        <h3 class="name">{{ item.provider.den_name }}</h3>
        <p class="desc">{{ item.banner_title }}</p>
      </div>
    </div>
  </article>
</template>

<script>
import ImageBlockSrc from '../ImageBlock/ImageBlockSrc.vue';
export default {
  components: { ImageBlockSrc },
  props: ['item'],
};
</script>

<style></style>
