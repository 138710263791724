<template>
  <div class="wrap">
    <!-- header : END -->
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <div class="left_header">
            <router-link to="/brand" class="menu fontEn">{{
              $t('Brand.RANKING')
            }}</router-link>
            <router-link to="/brand/look-book" class="menu fontEn on">
              {{ $t('Brand.LOOKBOOK') }}
            </router-link>
          </div>
          <search-cart-icons />
        </nav>
      </div>
    </header>
    <filter-slider
      v-if="selected.length > 0"
      :items="selected"
      @click="handleDeleteFilterItem"
    />
    <div
      v-if="isLoading"
      class="layout_body box_p60"
      :style="selected.length ? 'padding-top: 96px' : ''"
    >
      <lookbook-item
        v-for="item in items"
        :key="item.spp_id"
        :item="item"
        @click="handleClickLookBook"
      />
    </div>
    <div v-else class="loading_wrap">
      <div class="loading_content">
        <img src="../../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <sild-footer isToggleNav="true" />
    <button
      v-if="isLoading"
      type="button"
      class="band_filter btn_fix btn_lineblack btn_pop_open"
      @click="isShowFilter = true"
    >
      {{ $t('Brand.LookBookFilter') }}
    </button>
    <look-book-filter
      v-if="isShowFilter"
      @submit="handleSubmitFilter"
      @close="isShowFilter = false"
    />
    <look-book-detail-popup
      v-if="isShowDetail"
      :spp_id="spp_id"
      @close="isShowDetail = false"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BrandAPI from '../../apis/BrandAPI';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import LookbookItem from '../../components/ui/LookbookItem/LookbookItem.vue';
import LookBookFilter from './popups/LookBookFilter.vue';
import FilterSlider from '../../components/ui/FilterSlider/FilterSlider.vue';
import LookBookDetailPopup from './popups/LookBookDetailPopup.vue';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';

export default {
  components: {
    SildFooter,
    LookbookItem,
    LookBookFilter,
    FilterSlider,
    LookBookDetailPopup,
    SearchCartIcons,
  },
  setup() {
    const state = reactive({
      items: [],
      selected: [],
      spp_id: null,
      isShowDetail: false,
      isShowFilter: false,
      selectLookBookItem: null,
      isLoading: false,
    });

    const loadDatas = async () => {
      const { data } = await BrandAPI.getLookbookList({
        page: 0,
        limit: 10,
        den_id: state.selected.map(item => item.den_id).join(','),
      });

      state.items = data.result_data;
      state.isLoading = true;
    };

    loadDatas();

    return {
      ...toRefs(state),
      handleClickLookBook(item) {
        state.selectLookBookItem = item.den_id;
        state.spp_id = item.spp_id;
        state.isShowDetail = true;
      },
      handleSubmitFilter(selected) {
        state.selected = [...selected];
        state.isShowFilter = false;
        loadDatas();
      },

      handleDeleteFilterItem(item) {
        state.selected = state.selected.filter(
          val => val.den_id !== item.den_id,
        );
        loadDatas();
      },
    };
  },
};
</script>

<style>
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
