<template>
  <filter-layout-popup
    :title="$t('Brand.LookBookFilter')"
    class="look-book-filter"
    @reset="handleReset"
    @submit="handleSubmit"
    @close="$emit('close')"
  >
    <template>
      <div class="layout_box">
        <div class="pop_header btwlist">
          <p class="con w50">
            <label class="chkbox">
              <input type="checkbox" @change="handleChangeMyBrand" />
              {{ $t('Brand.PickBrand') }}
            </label>
          </p>
        </div>
        <div class="pop_header bordernone inputWrap">
          <form @submit="handleSearch">
            <input
              v-model="keyword"
              class="gray_search mt14"
              type="search"
              :placeholder="$t('Brand.BrandNameSearch')"
            />
            <button
              type="button"
              class="btnClear"
              @click="handleSearch"
            ></button>
          </form>
        </div>

        <!-- 태그 추가부분 -->
        <div class="pop_header bordernone disflex all_about">
          <span
            >{{ $t('Brand.Total') }} {{ items.length.numberFormat() }}
            {{ $t('Brand.CountBrand') }}</span
          >
          <button
            type="button"
            class="abc_order"
            @click="filterTypeIdx = filterTypeIdx === 2 ? 0 : filterTypeIdx + 1"
            v-text="filterButtons[filterTypeIdx].label"
          />
        </div>

        <!-- 검색전 브랜드 목록 -->
        <div v-if="isSearch === false" class="pop_body height_full">
          <div
            class="chk_btn_list"
            :style="`height: calc(100% - ${
              selected.length > 0 ? '50px' : '0px'
            });`"
          >
            <button
              v-for="val in buttons[filterButtons[filterTypeIdx].key]"
              :key="val"
              type="button"
              :class="selectedTab === val ? 'on' : ''"
              @click="handleClickTab(val)"
              v-text="val"
            />
          </div>
          <div class="chk_list_box">
            <ul class="select_list_box concept on">
              <look-book-filter-item
                v-for="item in items"
                :key="item.den_id"
                :item="item"
                :value="isSelected(item)"
                @change="handleChangeFilterItem"
              />
            </ul>
          </div>
        </div>

        <!-- 검색 결과 -->
        <div v-if="isSearch" class="search_list_box">
          <ul v-if="items.length > 0" class="search_list">
            <look-book-filter-item
              v-for="item in items"
              :key="item.den_id"
              :item="item"
              :value="isSelected(item)"
              style="line-height: 40px"
              @change="handleChangeFilterItem"
            />
          </ul>
          <div v-else class="no-brand">
            {{ isMyPick ? $t('Join.Picked') : $t('Join.Searched') }}
            브랜드가 없습니다.
          </div>
        </div>

        <filter-slider
          v-if="selected.length > 0"
          :items="selected"
          style="bottom: 40px"
          @click="handleDeleteFilterItem"
        />
      </div>
    </template>
  </filter-layout-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BrandAPI from '../../../apis/BrandAPI';
// import useUserInfo from '../../../composables/user/useUserInfo';
import LookBookFilterItem from './LookBookFilterItem.vue';
import { transformChoseongChar } from '@/utils/StringUtils';
import FilterSlider from '../../../components/ui/FilterSlider/FilterSlider.vue';
import ArrayUtils from '../../../utils/ArrayUtils';
import FilterLayoutPopup from '../../../components/popups/FilterLayoutPopup.vue';
import useToast from '../../../composables/dialogs/useToast';

export default {
  components: { LookBookFilterItem, FilterSlider, FilterLayoutPopup },
  setup(_, context) {
    // const { info } = useUserInfo();
    const { addMsg } = useToast();

    const state = reactive({
      items: [],
      selected: [],
      selectedTab: 'all',
      isSearch: false,
      filterTypeIdx: 0,
      keyword: '',
      isMyPick: false,
      filterResetItem: [],
    });

    const brands = {};

    const mem_id = localStorage.getItem('mem_id') ?? 0;

    const loadFilter = async () => {
      const { data } = await BrandAPI.getBrandData({
        mem_id: mem_id,
      });
      state.items = data;
      state.filterResetItem = data;
      brands['all'] = data;
      groupByBrands(data);
    };

    const setItems = async isRest => {
      if (isRest === 'reset') {
        const { data } = await BrandAPI.getBrandData({
          mem_id: mem_id,
        });
        state.items = data;
      } else {
        const { data } = await BrandAPI.getBrandData({
          mem_id: mem_id,
        });
        state.items = data;
        let rCho = [
          'ㄱ',
          'ㄲ',
          'ㄴ',
          'ㄷ',
          'ㄸ',
          'ㄹ',
          'ㅁ',
          'ㅂ',
          'ㅃ',
          'ㅅ',
          'ㅆ',
          'ㅇ',
          'ㅈ',
          'ㅉ',
          'ㅊ',
          'ㅋ',
          'ㅌ',
          'ㅍ',
          'ㅎ',
        ];
        const items = state.items.filter(items => {
          let sTest = items.den_name.substr(0, 1);
          let nTmp = sTest.charCodeAt(0) - 0xac00;
          let cho, jung, jong;
          jong = nTmp % 28; // 종성
          jung = ((nTmp - jong) / 28) % 21; // 중성
          cho = ((nTmp - jong) / 28 - jung) / 21; // 종성
          // console.log(
          //     "초성:"+rCho[cho]+"\n"
          //     +"중성:"+rJung[jung]+"\n"
          //     +"종성:"+rJong[jong]
          // );
          if (
            state.selectedTab === rCho[cho] ||
            state.selectedTab.includes(items.den_name.substr(0, 1))
          ) {
            return items;
          }
        });
        state.items = [...items];
      }
    };

    const groupByBrands = items => {
      items.forEach(item => {
        const firtName = transformChoseongChar(
          item.den_name.toUpperCase(),
        ).toLowerCase();
        if (!brands[firtName]) brands[firtName] = [];

        brands[firtName].push(item);
      });
    };

    let myPickBrands = null;

    const loadMyPickBrand = async () => {
      const { data } = await BrandAPI.getMyPickBrands({
        mem_id: mem_id,
      });
      // console.log('pickBrands', data);

      myPickBrands = data.result_data;
    };

    const getMyPickBrand = async () => {
      if (!myPickBrands) {
        await loadMyPickBrand();
      }

      return myPickBrands;
    };

    // loadBrands();
    loadFilter();

    const filterButtons = [
      { key: 'abc', label: context.root.$t('Brand.OrderByEng') },
      { key: 'kr', label: context.root.$t('Brand.OrderByKr') },
      { key: 'num', label: context.root.$t('Brand.OrderByNum') },
    ];

    return {
      ...toRefs(state),

      handleClickTab(val) {
        state.selectedTab = val;
        setItems();
      },

      handleChangeFilterItem(e, item) {
        const newItem = {
          ...item,
          key: item.den_id,
          label: item.den_name,
          target: e.target,
        };
        const selected = [...state.selected];

        ArrayUtils.toggleByAttribute(selected, newItem, 'key');

        state.selected = selected;
      },

      handleDeleteFilterItem(item) {
        const selected = [...state.selected];

        ArrayUtils.toggleByAttribute(selected, item, 'key');

        state.selected = selected;

        const target = document.querySelector(`[data-denId=${item.den_id}]`);

        target.checked = false;
      },

      async handleChangeMyBrand(e) {
        if (e.target.checked) {
          state.items = await getMyPickBrand();
        } else {
          this.handleReset();
        }

        state.isMyPick = e.target.checked;
        state.isSearch = e.target.checked;
      },
      isSelected(item) {
        return state.selected.findIndex(val => val.den_id === item.den_id) > -1;
      },
      handleSearch(e) {
        e.preventDefault();

        if (state.keyword.length === 0) {
          addMsg(context.root.$t('Brand.BrandNameEnter'));
          return;
        }

        const searchList = state.items.map(itemValue => {
          let denName = itemValue.den_name;
          if (denName) {
            if (
              denName.includes(state.keyword) ||
              denName.toLowerCase().includes(state.keyword)
            ) {
              console.log('-----');
              console.log(itemValue.den_name);
              console.log(itemValue);
              return itemValue;
            }
          }
        });

        state.items = [...searchList];

        // // 첫번째 글자를 초성으로 변경해서 가져옴
        // // 브랜드를 초성을 기준으로 그룹핑 했기때문
        // const key = transformChoseongChar(state.keyword[0].toLowerCase());
        //
        // // 첫번째 글자부터 매치되는지 확인
        // const items = brands[key] ? brands[key] : [];
        // console.log(items);
        // state.items = items.filter(
        //   (item) =>
        //     item.den_name.toLowerCase().indexOf(state.keyword.toLowerCase()) ===
        //     0
        // );

        state.isSearch = true;
      },
      handleReset() {
        state.selectedTab = 'all';
        state.isSearch = false;
        state.filterTypeIdx = 0;
        state.selected = [];
        setItems('reset');
      },

      handleSubmit() {
        if (state.selected.length === 0) {
          addMsg(context.root.$t('Brand.BrandNameSelect'));
          return;
        }

        context.emit('submit', state.selected);
      },

      filterButtons,
      buttons: {
        abc,
        kr,
        num,
      },
    };
  },
};

const num = '0123456789'.split('');
const kr = 'ㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ'.split('');
const abc = 'QWERTYUIOPASDFGHJKLZXCVBNM'.split('').sort((a, b) => {
  return a > b ? 1 : -1;
});
</script>

<style>
.chk_btn_list {
  height: calc(100% - 276px);
  overflow: scroll;
}

.no-brand {
  text-align: center;
  font-weight: bold;
  padding-top: 50px;
}
.look-book-filter .filter_selec_box button {
  border: 1px solid #202020 !important;
  color: #202020;
  background: url(~@/assets/images/design/close-bt.svg) no-repeat right 3px
    center !important;
}
</style>
