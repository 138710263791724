<template>
  <li class="look-book-filter-item">
    <label class="chkbox">
      <input
        type="checkbox"
        :checked="value"
        :data-denId="item.den_id"
        @change="handleChange"
      />
      {{ item.den_name }}
    </label>
  </li>
</template>

<script>
export default {
  props: ['item', 'value'],
  setup(props, context) {
    return {
      handleChange(e) {
        context.emit('change', e, props.item);
      },
    };
  },
};
</script>

<style scoped>
.look-book-filter-item {
  line-height: 40px;
}
.look-book-filter-item label {
  display: block;
  font-size: 12px;
}
.look-book-filter-item label input {
  margin-right: 5px;
}
</style>
