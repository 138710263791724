<template>
  <down-full-up-popup :title="spp_id" class="look-book-detail-popup">
    <look-book-detail slot="body" :spp_id="spp_id" @close="$emit('close')" />
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../../components/popups/DownFullUpPopup.vue';
import LookBookDetail from './LookBookDetail.vue';

export default {
  props: ['spp_id'],
  components: { DownFullUpPopup, LookBookDetail },
};
</script>

<style>
.look-book-detail-popup .pop_header {
  display: none;
}

.look-book-detail-popup .pop_body,
.look-book-detail-popup .inner,
.look-book-detail-popup .lookbook_banner .sinner {
  padding: 0;
}
</style>
