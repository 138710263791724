export default {
  delete(items, deleteItem) {
    const idx = items.indexOf(deleteItem);

    if (idx > -1) items.splice(idx, 1);
  },
  reset(items) {
    items.splice(0, items.length);
  },
  toggle(items, item) {
    const idx = items.indexOf(item);

    idx === -1 ? items.push(item) : items.splice(idx, 1);

    return idx === -1;
  },
  toggleByAttribute(items, item, attrName = '') {
    const idx = items.findIndex(obj => obj[attrName] === item[attrName]);

    idx === -1 ? items.push(item) : items.splice(idx, 1);

    return idx === -1;
  },
  moveItem(items, item, moveSize = 1) {
    // 배열값이 없는 경우 나가기
    if (items.length < 0) return;

    const idx = items.indexOf(item);

    // 이동할 index 값을 변수에 선언
    const newPosition = idx + moveSize;

    // 이동할 값이 0보다 작거나 최대값을 벗어나는 경우 종료
    if (newPosition < 0 || newPosition >= items.length) return;

    // 옮길 대상을 target 변수에 저장하기
    const target = items.splice(idx, 1)[0];

    // 새로운 위치에 옮길 대상을 추가하기
    items.splice(newPosition, 0, target);
  },

  replaceByAttributeName(items, item, name) {
    const newItems = Object.keys(items).map(valuess => {
      return { den_id: valuess };
    });
    const idx = newItems.findIndex(value => value[name] === item[name]);

    if (idx > -1) newItems[idx] = item;

    return newItems;
  },
};
