// 초성으로 변환 1글자만
export const transformChoseongChar = char => {
  const cho = [
    'ㄱ',
    'ㄲ',
    'ㄴ',
    'ㄷ',
    'ㄸ',
    'ㄹ',
    'ㅁ',
    'ㅂ',
    'ㅃ',
    'ㅅ',
    'ㅆ',
    'ㅇ',
    'ㅈ',
    'ㅉ',
    'ㅊ',
    'ㅋ',
    'ㅌ',
    'ㅍ',
    'ㅎ',
  ];

  var index = Math.floor((char.charCodeAt() - 44032) / 588);

  return cho[index] || char;
};

// 초성으로 변환 문자
export const transformChoseongString = str => {
  return str.split('').map(transformChoseongChar).join('');
};
